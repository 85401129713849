import React, { useState } from 'react';
import clsx from 'clsx';
import muiStyles from './muiStyle';
import '../App.css';
import '../css/common.css';
import BackGroundImg from '../images/background2.png';
import { useEffect } from 'react';
import useLiff from '../hooks/useAuth';
import liff from '@line/liff'
import axios from 'axios';
import { API } from '../hooks/api';
import { makeStyles } from '@material-ui/core';
import { availableMonths, encode, getDateList, getYearList, zeroPadding } from './helpper';
import moment from 'moment';
import Loading from '../components/Loading';
import { useHistory } from 'react-router';
import { useQuery } from 'react-query'
import { checkProfile, getModel } from '../hooks/queries'
import { ModalVideoWithLabel } from "./ModalVideoWithLabel"

const useStyles = makeStyles(({
    container: {
        backgroundImage: `url(${BackGroundImg})`,
        backgroundPosition: '0% 0%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        minHeight: '100vh',
    },
}));

const Index = () => {
    const classes = muiStyles();
    const classes2 = useStyles();
    const [loading, setLoading] = useState(false);

    const [language, setLanguage] = useState('th');
    const [showAgreement, setShowAgreement] = useState(false);
    const history = useHistory();
    const { getProfile, getAccessToken } = useLiff();
    let isShow = false
    let modelList = []
    let dateList = []
    let yearList = []

    const queryParams = new URLSearchParams(window.location.search);
    const branchId = queryParams.get('branchId');

    const token = getAccessToken
    const profile = getProfile

    const param = {
        accesstoken: token,
        lineuserid: profile?.userId,
    }
    const encodedData = encode(param);

    const { data: checkProfileData, isSuccess } = useQuery('checkProfile', () => checkProfile(encodedData), { enabled: profile !== undefined })
    const { data: models } = useQuery('getModel', () => getModel(encodedData))

    modelList = models?.data ?? []

    if (checkProfileData?.detail === 'profile_existing') {
        const queryParams = new URLSearchParams(window.location.search);
        const activePage = queryParams.get('activePage');

        if (activePage === 'privilege') {
            // window.location = `/privileges`
            history.push(`/privileges?branchId=${branchId}`);
        }
        else if (activePage === 'mycoupon') {
            // window.location = `/my-new-coupon`
            history.push(`/my-new-coupon?branchId=${branchId}`);
        }
        else if (activePage === 'myprofile') {
            // window.location = `/my-profile`
            history.push(`/my-profile?branchId=${branchId}`);
        }
        else {
            // window.location = `/privileges`
            history.push(`/privileges?branchId=${branchId}`);
        }
    }
    else {
        dateList = getDateList()
        yearList = getYearList()
        isShow = true;
    }

    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        postCode: '',
        mobile: '',
        gender: 'male',
        date: '',
        month: '',
        year: '',
        haveMyOwn: {
            status: true,
            model: '',
        },
        engineNumber: '',
        bodyNumber: '',
        isAcceptAgreement: null
    })

    const [blur, serBlur] = useState({
        firstName: false,
        lastName: false,
        postCode: false,
        mobile: false,
        model: false,
        date: false,
        month: false,
        year: false,
    })

    const onChangeInput = (e) => {
        let { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
        serBlur({
            ...blur,
            [name]: true
        })
    }

    const onChangeInputModel = (e) => {
        let { name, value } = e.target;
        setData({
            ...data,
            haveMyOwn: {
                ...data.haveMyOwn,
                [name]: value
            },
        })
        serBlur({
            ...blur,
            [name]: true
        })
    }

    const onBlur = (e) => {
        serBlur({
            ...blur,
            [e.target.name]: true
        })
    }

    const onChangeGender = (e, value) => {
        setData({
            ...data,
            gender: value
        })
    }

    const onChangeStatus = (e, value) => {
        setData({
            ...data,
            haveMyOwn: {
                ...data.haveMyOwn,
                status: value
            },
            engineNumber: value ? data.engineNumber : '',
        })
    }

    const onChangeIsAccepted = (e, value) => {
        setData({
            ...data,
            isAcceptAgreement: value
        })
    }

    const cancelClickHandler = () => {
        liff.closeWindow();
    }

    const onSubmit = () => {
        serBlur({
            firstName: true,
            lastName: true,
            postCode: true,
            mobile: true,
            model: true,
            date: true,
            month: true,
            year: true,
        })

        const birthday = `${zeroPadding(data.date, 2)}/${zeroPadding(data.month, 2)}/${data.year}`

        if (
            !data.firstName || !data.lastName ||
            !data.date || !data.month || !data.year ||
            !data.haveMyOwn.model || !data.postCode || !data.mobile
        ) {
            console.log("fill input");
        }
        else if (data.postCode.length < 5 || isNaN(data.postCode)) {
            console.log("error postcode");
        }
        else if (data.mobile.length < 10 || isNaN(data.mobile)) {
            console.log("error mobile");
        }
        else if (data.engineNumber && (!(/^[a-zA-Z0-9]+$/.test(data.engineNumber)) || data.engineNumber.length < 12)) {
            console.log("error engine number");
        }
        else if (!moment(birthday, "DD/MM/YYYY", true).isValid()) {
            alert(language === 'en' ? 'Invalid birthdate.' : 'วันเกิดไม่ถูกต้อง');
        }
        else {
            const param = {
                accesstoken: token,
                lineuserid: profile.userId,
                firstname: data.firstName,
                lastname: data.lastName,
                mobile: data.mobile,
                gender: data.gender,
                bhd: `${data.year}-${zeroPadding(data.month, 2)}-${zeroPadding(data.date, 2)}`,
                postal: data.postCode,
                vespaowner: data.haveMyOwn.status << 0,
                vespamodel: data.haveMyOwn.model,
                enginenumber: data.haveMyOwn.status === true ? data.engineNumber : "",
                chassisnumber: data.haveMyOwn.status === true ? data.bodyNumber : "",
                lat: "",
                long: "",
                lang: language,
                branchid: branchId
            }
            console.log("add param", param);
            const encodedData = encode(param);
            setLoading(true);
            (async () => {
                const response = await axios.post(API.CREATE_NEW_USER, encodedData);
                console.log("response", response);
                if (response?.data?.result === "complete") {
                    window.location = `/privileges?branchId=${branchId}`
                }
                else {
                    setLoading(false);
                    alert(response?.data?.detail || 'ลงทะเบียนไม่สำเร็จ');
                }
            })();
        }
    }

    useEffect(() => {
        setData({
            firstName: '',
            lastName: '',
            postCode: '',
            mobile: '',
            gender: 'male',
            date: '',
            month: '',
            year: '',
            haveMyOwn: {
                status: true,
                model: '',
            },
            engineNumber: '',
            bodyNumber: '',
            isAcceptAgreement: null
        })
    }, [language])

    return (<>
        {
            !isShow || !isSuccess ? (
                <div className={classes2.container}></div>
            ) :
                <div className={classes2.container}>
                    {loading && <Loading />}

                    <div className="header_profile">
                        <div className={classes.avatarFrame}>
                            <img className="avatar__image" src={profile?.pictureUrl} alt="" />
                        </div>
                        <div className={classes.language}>
                            <a
                                className={language === 'th' ? classes.languageAnchorActive : classes.languageAnchor}
                                onClick={e => setLanguage('th')}
                            >TH</a>
                            |
                            <a
                                className={language === 'en' ? classes.languageAnchorActive : classes.languageAnchor}
                                onClick={e => setLanguage('en')}
                            >EN</a>
                        </div>
                    </div>

                    <form className={classes.form}>
                        <div className={classes.formItem}>
                            <label className={classes.formLabel}>
                                {language === 'en' ? 'First name*' : 'ชื่อ*'}
                            </label>
                            <div>
                                <input className={classes.formFieldInput}
                                    placeholder={language === 'en' ? 'First name' : 'ชื่อ'}
                                    type="text"
                                    name="firstName"
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    value={data.firstName}
                                />
                            </div>
                            {!data.firstName && blur.firstName && (
                                <div className={classes.formFieldError}>
                                    {language === 'en' ? 'This field is required.' : 'กรุณากรอกข้อมูล'}
                                </div>
                            )}
                        </div>

                        <div className={classes.formItem}>
                            <label className={classes.formLabel}>
                                {language === 'en' ? 'Last name*' : 'นามสกุล*'}
                            </label>
                            <div>
                                <input className={classes.formFieldInput}
                                    placeholder={language === 'en' ? 'Last name' : 'นามสกุล'}
                                    type="text"
                                    name="lastName"
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    value={data.lastName}
                                />
                            </div>
                            {!data.lastName && blur.lastName && (
                                <div className={classes.formFieldError}>
                                    {language === 'en' ? 'This field is required.' : 'กรุณากรอกข้อมูล'}
                                </div>
                            )}
                        </div>

                        <div className={classes.formItem}>
                            <label className={classes.formLabel}>
                                {language === 'en' ? 'Gender' : 'เพศ*'}
                            </label>
                            <label className={classes.formFieldWrap}>
                                <input
                                    className={classes.formFieldRadio}
                                    type="radio"
                                    value={data.gender === 'male'}
                                    name="gender"
                                    onChange={e => onChangeGender(e, 'male')}
                                />
                                <div className={data.gender === 'male' ? clsx(classes.formRadio, classes.formRadioBgCheck) : clsx(classes.formRadio, classes.formRadioBg)}></div>
                                <span className={classes.formFieldTitle}>
                                    {language === 'en' ? 'Male' : 'ชาย'}
                                </span>
                            </label>
                            <label className={classes.formFieldWrap}>
                                <input
                                    className={classes.formFieldRadio}
                                    type="radio"
                                    value={data.gender === 'female'}
                                    name="gender"
                                    onChange={e => onChangeGender(e, 'female')}
                                />
                                <div className={data.gender === 'female' ? clsx(classes.formRadio, classes.formRadioBgCheck) : clsx(classes.formRadio, classes.formRadioBg)}></div>
                                <span className={classes.formFieldTitle}>
                                    {language === 'en' ? 'Female' : 'หญิง'}
                                </span>
                            </label>
                        </div>

                        <div className={classes.formItem}>
                            <label className={classes.formLabel}>
                                {language === 'en' ? 'Birth date*' : 'วัน/เดือน/ปี เกิด*'}
                            </label>
                            <div style={{ width: '100%', display: 'flex' }}>
                                <div className={classes.formBirthDayDate}>
                                    <select
                                        className={data.date ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                        name="date"
                                        value={data.date}
                                        onChange={onChangeInput}
                                        onBlur={onBlur}
                                    >
                                        <option value="">วันที่</option>
                                        {dateList.map(m => (
                                            <option value={m} key={m}>{m}</option>
                                        ))}
                                    </select>
                                    {!data.date && blur.date && (
                                        <div className={classes.formFieldError}>
                                            {language === 'en' ? 'This field is required.' : 'กรุณาระบุวันที่'}
                                        </div>
                                    )}
                                </div>
                                <div className={classes.formBirthDayMonth}>
                                    <select
                                        className={data.month ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                        name="month"
                                        value={data.month}
                                        onChange={onChangeInput}
                                        onBlur={onBlur}
                                    >
                                        <option value="">เดือน</option>
                                        {availableMonths.map((m, i) => (
                                            <option value={i + 1} key={m.en}>{language === 'en' ? m.en : m.th}</option>
                                        ))}
                                    </select>
                                    {!data.month && blur.month && (
                                        <div className={classes.formFieldError}>
                                            {language === 'en' ? 'This field is required.' : 'กรุณาระบุเดือน'}
                                        </div>
                                    )}
                                </div>
                                <div className={classes.formBirthDayYear}>
                                    <select
                                        className={data.year ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                        name="year"
                                        value={data.year}
                                        onChange={onChangeInput}
                                        onBlur={onBlur}
                                    >
                                        <option value="">ปี</option>
                                        {yearList.map(m => (
                                            <option value={m.en} key={m.en}>{language === 'en' ? m.en : m.th}</option>
                                        ))}
                                    </select>
                                    {!data.year && blur.year && (
                                        <div className={classes.formFieldError}>
                                            {language === 'en' ? 'This field is required.' : 'กรุณาระบุปี'}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={classes.formItem}>
                            <label className={classes.formLabel}>
                                {language === 'en' ? 'Postcode (current address)' : 'รหัสไปรษณีย์ (ตามที่อยู่ปัจจุบัน)'}
                            </label>
                            <div>
                                <input className={classes.formFieldInput}
                                    placeholder={language === 'en' ? '5 digits number' : 'หมายเลข 5 หลัก'}
                                    type="text"
                                    name="postCode"
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    maxLength={5}
                                    value={data.postCode}
                                />
                            </div>
                            {!data.postCode && blur.postCode && (
                                <div className={classes.formFieldError}>
                                    {language === 'en' ? 'This field is required.' : 'กรุณากรอกข้อมูล'}
                                </div>
                            )}
                            {data.postCode && (
                                <>
                                    {isNaN(data.postCode) ? (
                                        <div className={classes.formFieldError}>
                                            {language === 'en' ? 'Fill in numbers only.' : 'กรอกเป็นตัวเลขเท่านั้น'}
                                        </div>
                                    ) : data.postCode.length < 5 ? (
                                        <div className={classes.formFieldError}>
                                            {language === 'en' ? '5 digit numbers.' : 'กรอกตัวเลข 5 หลัก'}
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>

                        <div className={classes.formItem}>
                            <label className={classes.formLabel}>
                                {language === 'en' ? 'Mobile' : 'เบอร์โทรศัพท์'}
                            </label>
                            <div>
                                <input className={classes.formFieldInput}
                                    placeholder={language === 'en' ? '10 digits number' : 'หมายเลข 10 หลัก'}
                                    type="text"
                                    name="mobile"
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    maxLength={10}
                                    value={data.mobile}
                                />
                            </div>
                            {!data.mobile && blur.mobile && (
                                <div className={classes.formFieldError}>
                                    {language === 'en' ? 'This field is required.' : 'กรุณากรอกข้อมูล'}
                                </div>
                            )}
                            {data.mobile && (
                                <>
                                    {isNaN(data.mobile) ? (
                                        <div className={classes.formFieldError}>
                                            {language === 'en' ? 'Fill in numbers only.' : 'กรอกเป็นตัวเลขเท่านั้น'}
                                        </div>
                                    ) : data.mobile.length < 10 ? (
                                        <div className={classes.formFieldError}>
                                            {language === 'en' ? '10 digit numbers.' : 'กรอกตัวเลข 10 หลัก'}
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>

                        <div className={classes.formItem}>
                            <label className={classes.formLabel}>
                                {language === 'en' ? 'Have a Vespa?' : 'มี Vespa หรือไม่'}
                            </label>
                            <label className={classes.formFieldWrap}>
                                <input
                                    className={classes.formFieldRadio}
                                    type="radio"
                                    name="status"
                                    value={data.haveMyOwn.status === true}
                                    onChange={e => onChangeStatus(e, true)}
                                />
                                <div className={data.haveMyOwn.status === true ? clsx(classes.formRadio, classes.formRadioBgCheck) : clsx(classes.formRadio, classes.formRadioBg)}></div>
                                <span className={classes.formFieldTitle}>
                                    {language === 'en' ? 'Yes' : 'มี'}
                                </span>
                            </label>
                            <label className={classes.formFieldWrap}>
                                <input
                                    className={classes.formFieldRadio}
                                    type="radio"
                                    name="status"
                                    value={data.haveMyOwn.status === false}
                                    onChange={e => onChangeStatus(e, false)}
                                />
                                <div className={data.haveMyOwn.status === false ? clsx(classes.formRadio, classes.formRadioBgCheck) : clsx(classes.formRadio, classes.formRadioBg)}></div>
                                <span className={classes.formFieldTitle}>
                                    {language === 'en' ? 'No' : 'ไม่มี'}
                                </span>
                            </label>
                        </div>

                        <div className={classes.formItem}>
                            <label className={classes.formLabel}>
                                {language === 'en' ? 'Have / interested in a model?' : 'มี / สนใจ Vespa รุ่น'}
                            </label>
                            <select
                                className={data.haveMyOwn.model ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                name="model"
                                value={data.haveMyOwn.model}
                                onChange={onChangeInputModel}
                                onBlur={onBlur}
                            >
                                <option value="">เลือกรุ่น</option>
                                {modelList?.map(m => (
                                    <option value={m.titleth} key={m.itemid}>{m.titleth}</option>
                                ))}
                            </select>
                            {!data.haveMyOwn.model && blur.model && (
                                <div className={classes.formFieldError}>
                                    {language === 'en' ? 'This field is required.' : 'กรุณากรอกข้อมูล'}
                                </div>
                            )}
                        </div>

                        {data.haveMyOwn.status && (
                            <>
                                <div className={classes.formItem}>
                                    <label className={classes.formLabel}>
                                        {language === 'en' ? 'Engine number' : 'เลขเครื่องยนต์'}
                                    </label>
                                    <div>
                                        <input className={classes.formFieldInput}
                                            placeholder={language === 'en' ? 'Engine number' : 'เลขเครื่องยนต์'}
                                            type="text"
                                            name="engineNumber"
                                            onChange={onChangeInput}
                                            value={data.engineNumber}
                                            maxLength={12}
                                        />
                                    </div>
                                    {data.engineNumber && (
                                        <>
                                            {!(/^[a-zA-Z0-9]+$/.test(data.engineNumber)) ? (
                                                <div className={classes.formFieldError}>
                                                    {language === 'en' ? 'Invalid Engine number.' : 'เลขเครื่องยนต์ไม่ถูกต้อง'}
                                                </div>
                                            ) : data.engineNumber.length < 12 ? (
                                                <div className={classes.formFieldError}>
                                                    {language === 'en' ? '12 digit.' : 'ความยาว 12 หลัก'}
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                </div>

                                <div className={classes.formItem}>
                                    <label className={classes.formLabel}>
                                        {language === 'en' ? 'Tank number' : 'เลขถัง'}
                                    </label>
                                    <div>
                                        <input className={classes.formFieldInput}
                                            placeholder={language === 'en' ? 'Tank number' : 'เลขถัง'}
                                            type="text"
                                            name="bodyNumber"
                                            onChange={onChangeInput}
                                            value={data.bodyNumber}
                                            maxLength={17}
                                        />
                                    </div>
                                    {data.bodyNumber && (
                                        <>
                                            {!(/^[A-Za-z]{2}[0-9]/.test(data.bodyNumber)) ? (
                                                <div className={classes.formFieldError}>
                                                    {language === 'en' ? 'Input must start with two letters followed by one number.' : 'ข้อมูลที่ป้อนต้องเริ่มต้นด้วยตัวอักษรสองตัวและตามด้วยตัวเลขหนึ่งตัว'}
                                                </div>
                                            ) : (<>
                                                {!(/^[a-zA-Z0-9]+$/.test(data.bodyNumber)) ? (
                                                    <div className={classes.formFieldError}>
                                                        {language === 'en' ? 'Invalid Tank number.' : 'เลขถังไม่ถูกต้อง'}
                                                    </div>
                                                ) : data.bodyNumber.length < 17 ? (
                                                    <div className={classes.formFieldError}>
                                                        {language === 'en' ? '17 digit.' : 'ความยาว 17 หลัก'}
                                                    </div>
                                                ) : null}
                                            </>
                                            )}
                                        </>
                                    )}
                                </div>
                                <ModalVideoWithLabel label={language === 'en' ? 'Click here to learn how to view tank numbers.' : 'คลิกดูวิธีการดูเลขถังที่นี่'} videoId={"N_8PQ71HEsU"} />
                            </>
                        )}

                        <div>
                            <label className={classes.formFieldWrap2}>
                                <input
                                    className={classes.formFieldRadio}
                                    type="radio"
                                    name="isAcceptAgreement"
                                    value={data.isAcceptAgreement === true}
                                    onChange={e => onChangeIsAccepted(e, true)}
                                />
                                <div className={data.isAcceptAgreement === true ? clsx(classes.formRadio, classes.formRadioBgCheck) : clsx(classes.formRadio, classes.formRadioBg)}></div>
                                <span className={classes.formFieldTitle}>
                                    {language === 'en' ? 'Accept ' : 'ยอมรับ'}
                                </span>
                            </label>
                            <label className={classes.formFieldLink}>
                                <a onClick={e => setShowAgreement(!showAgreement)}>
                                    {language === 'en' ? 'Terms & Conditions' : 'เงื่อนไขและข้อตกลง'}
                                    <label className={classes.formFieldWrapAccept}></label>
                                </a>
                            </label>
                            <label className={classes.formFieldWrap2}>
                                <input
                                    className={classes.formFieldRadio}
                                    type="radio"
                                    name="isAcceptAgreement"
                                    value={data.isAcceptAgreement === false}
                                    onChange={e => onChangeIsAccepted(e, false)}
                                />
                                <div className={data.isAcceptAgreement === false ? clsx(classes.formRadio, classes.formRadioBgCheck) : clsx(classes.formRadio, classes.formRadioBg)}></div>
                                <span className={classes.formFieldTitle}>
                                    {language === 'en' ? 'Deny' : 'ไม่ยอมรับ'}
                                </span>
                            </label>
                        </div>

                        {showAgreement && (
                            <>
                                {language === 'en' ? (
                                    <div className="agree">
                                        <p class="agree__words">
                                            I agree, and give my consent, to Vespiario
                                            (Thailand) Co., Ltd. in order to collect, store, use
                                            or disclose my personal data, including, but not
                                            limit to, name, surname, telephone number, mobile
                                            number, email and other technology medium, for the
                                            purpose of customers’ communication with respect to
                                            news, promotion, or any benefit, for product/service
                                            development, or for product/service sales and
                                            marketing of the company and its group companies.
                                        </p>
                                        <div class="agree__words">
                                            {`I accept the `}
                                            <a href="https://www.vespa.co.th/privacy-policy"
                                            >Guidelines with respect to Personal Data
                                                Protection and Privacy Policy</a>
                                            {` of the company, and acknowledge my right to revoke
                                    my consent at any time.`}
                                        </div>
                                        <ul class="agree__list">
                                            <li class="agree__item">
                                                For more information or query, please contact
                                            </li>
                                            <li class="agree__item">Vespiario Thailand</li>
                                            <li class="agree__item">
                                                Email: info@vespiario.com
                                            </li>
                                            <li class="agree__item">Tel: 02-716-0617-23</li>
                                        </ul>
                                    </div>
                                ) :
                                    <div className="agree">
                                        <p className="agree__words">
                                            ข้าพเจ้าตกลงยินยอมให้ บริษัท เวสปิอาริโอ (ประเทศไทย)
                                            จำกัด เก็บรวบรวมและใช้หรือเปิดเผยข้อมูลของข้าพเจ้า
                                            อาทิเช่น ชื่อ นามสกุล หมายเลขโทรศัพท์ มือถือ อีเมล
                                            และสื่อเทคโนโลยีอื่นๆ
                                            เพื่อให้สามารถติดต่อข้าพเจ้าได้ในการแจ้งข่าวสาร
                                            สิทธิประโยชน์ใดๆ
                                            หรือเพื่อใช้ข้อมูลในการพัฒนาผลิตภัณฑ์และบริการ
                                            หรือเสนอขายผลิตภัณฑ์และบริการของบริษัทฯ
                                            และบริษัทในเครือ
                                        </p>
                                        <p class="agree__words">
                                            ข้าพเจ้าตกลงยอมรับ<a
                                                href="https://www.vespa.co.th/privacy-policy"
                                            >แนวปฏิบัติเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลและนโยบายความเป็นส่วนตัว</a
                                            >ของบริษัทฯ
                                            โดยข้าพเจ้าทราบถึงสิทธิในการเพิกถอนความยินยอมได้ทุกเมื่อ
                                        </p>
                                        <ul class="agree__list">
                                            <li class="agree__item">
                                                หากมีข้อสงสัยกรุณาติดต่อ
                                            </li>
                                            <li class="agree__item">Vespiario Thailand</li>
                                            <li class="agree__item">
                                                อีเมล: info@vespiario.com
                                            </li>
                                            <li class="agree__item">
                                                โทรศัพท์: 02-716-0617-23
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </>
                        )}

                        <div className="form__action">
                            <a className="form__cancel" onClick={cancelClickHandler}>
                                {language === 'en' ? 'Cancel' : 'ยกเลิก'}
                            </a>

                            <a className="form__submit"
                                disabled={!data.isAcceptAgreement}
                                onClick={onSubmit}
                            >
                                {language === 'en' ? 'Submit' : 'บันทึก'}
                            </a>
                        </div>
                    </form>
                </div>
        }
    </>)
}

export default Index;