import React, { useEffect, useState } from 'react';
import AppBar from '../components/AppBar';
import AppBarNone from '../components/AppBarNone';
import Loading from '../components/Loading';
import '../App.css';
import muiStyles from './muiStyle';
import useLiff from '../hooks/useAuth';
import axios from 'axios';
import { API, isDealer } from '../hooks/api';
import { encode } from './helpper';
import { useParams } from 'react-router-dom';

const PrivilegesDetail = () => {
    const { id } = useParams()
    const classes = muiStyles();
    const [loading, setLoading] = useState(false);

    const [isShow, setShow] = useState(false);

    const [data, setData] = useState({});
    const [termList, setTermList] = useState([]);

    const { getProfile, getAccessToken } = useLiff();
    const token = getAccessToken
    const profile = getProfile

    const queryParams = new URLSearchParams(window.location.search);
    const branchId = queryParams.get('branchId');

    useEffect(() => {
        (async () => {
            if (!token) return
            if (!profile?.userId) return
            await getCheckProfile();
        })();
    }, [token, profile]);

    const getCheckProfile = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.CHECK_PROFILE, encodedData);
        if (response?.data?.detail === 'profile_existing') {
            setShow(true);
            (async () => {
                setShow(true);
                await initMaster();
            })();
        }
        else {
            window.location = `/?branchId=${branchId}`
        }
    }

    const initMaster = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'Reward',
        }
        const encodedData = encode(param);
        const coupons = await axios.post(isDealer ? API.LOAD_COUPON_DEALER : API.LOAD_COUPON_LIST, encodedData);

        const items = coupons?.data?.items ?? []

        const find = items.find(f => f.itemid === id);
        setData(find);
    }

    useEffect(() => {
        setTermList((data?.termth || '').split('!!'));
    }, [data])

    const pickUpCoupon = async () => {
        setLoading(true);
        const param = {
            accesstoken: token,
            itemid: id,
            ...(isDealer && { dealerid: branchId })
        }

        const encodedData = encode(param);

        const url = API.PICK_UP_COUPON;
        const response = await axios.post(url, encodedData);
        if (response?.data?.result === "complete") {
            window.location = `/my-new-coupon?branchId=${branchId}`
        }
        else {
            setLoading(false);
            alert(response?.data?.detail || 'เกิดข้อผิดพลาดในการรับคูปอง');
        }
    }

    return (<>
        {!isShow ? (
            <AppBarNone />
        ) :
            <div className={classes.container}>
                <AppBar />

                <div className={classes.content}>
                    <div className={classes.wrapper}>
                        <div className={classes.headingPath}>
                            <a href={`/privileges?branchId=${branchId}`} className={classes.headingBack}>
                            </a>
                            <div className={classes.headingText}>
                                {data?.titleth || ''}
                            </div>
                        </div>

                        <div className={classes.coupon}>
                            <div className={classes.coupons}>
                                <img className="coupon__image" src={data?.assetrectangle} alt="coupon" />

                                <div className={classes.remark}>
                                    <div className={classes.remarkHeading}>
                                        เงื่อนไขการใช้คูปอง
                                    </div>
                                    <ul className={classes.remarkGroup}>
                                        {termList.map((m, i) => (
                                            <li className={classes.remarkList}>
                                                <span key={i}>{m}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={classes.action}>
                            <a className={classes.actionRedeem} onClick={pickUpCoupon}>รับคูปอง</a>
                        </div>
                    </div>
                </div>

                {loading && <Loading />}
            </div>
        }
    </>)
}

export default PrivilegesDetail;