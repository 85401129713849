import React, { useState } from 'react'
import ModalVideo from 'react-modal-video';

export const ModalVideoWithLabel = ({ label, videoId }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <React.Fragment>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setOpen(false)}
      />
      <button
        onClick={() => setOpen(true)}
        type={"button"}
        style={{
          fontSize: "22px",
          marginBottom: "8px",
          background: "none",
          border: "none",
          textDecoration: "underline",
          cursor: "pointer",
        }}>
        {label}
      </button>
    </React.Fragment >
  )
}
