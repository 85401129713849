import React, { useEffect, useState } from 'react';
import AppBar from '../components/AppBar';
import AppBarNone from '../components/AppBarNone';
import Loading from '../components/Loading';
import '../App.css';
import muiStyles from './muiStyle';
import useLiff from '../hooks/useAuth';
import axios from 'axios';
import { API, isDealer } from '../hooks/api';
import { encode } from './helpper';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

const PrivilegesDetailRandom = () => {
    const classes = muiStyles();
    const { id } = useParams()
    const [loading, setLoading] = useState(false);

    const [isShow, setShow] = useState(false);

    const [data, setData] = useState({});
    const [termList, setTermList] = useState([]);
    const [type, setType] = useState();
    const [picNoReward, setPicNoReward] = useState(false);

    const { getProfile, getAccessToken } = useLiff();

    const queryParams = new URLSearchParams(window.location.search);
    const branchId = queryParams.get('branchId');
    const token = getAccessToken
    const profile = getProfile


    useEffect(() => {
        (async () => {
            if (!token) return
            if (!profile?.userId) return
            await getCheckProfile();
        })();
    }, [token, profile]);

    const getCheckProfile = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.CHECK_PROFILE, encodedData);
        if (response?.data?.detail === 'profile_existing') {
            setShow(true);
            (async () => {
                setShow(true);
                await initMaster();
            })();
        }
        else {
            window.location = `/?branchId=${branchId}`
        }
    }

    const initMaster = async () => {
        const paramRandom = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'RewardRandom2022-2',
        }
        const encodedDataRandom = encode(paramRandom);
        const couponRandom = await axios.post(API.LOAD_RANDOM_COUPON, encodedDataRandom);

        const items = [
            ...(couponRandom?.data?.items || []),
        ];

        const find = items.find(f => f.type === id);
        console.log("coupon", find)
        setData(find);
        setType(find?.type);
    }

    useEffect(() => {
        setTermList((data?.termth || '').split('!!'));
    }, [data])

    const pickUpCoupon = async () => {
        setLoading(true);
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: "RewardRandom2022-2",
            ...(isDealer && { dealerid: branchId })
        }

        console.log("param", param);
        const encodedData = encode(param);

        var _api = API.PICK_UP_COUPON_RANDOM;
        const response = await axios.post(_api, encodedData);
        console.log("response", response);
        if (response?.data?.result === "complete") {
            let _coupnDetail = response?.data?.coupondetail[0];

            if (_coupnDetail?.remarken === "No Rewarded") {
                setPicNoReward(_coupnDetail?.assetsquare);
            }
            else {
                window.location = `/my-new-coupon?branchId=${branchId}`
            }
        }
        else {
            setLoading(false);
            alert(response?.data?.detail || 'เกิดข้อผิดพลาดในการรับคูปอง');
        }
    }

    const backToPrillvges = () => {
        window.location = `/privileges?branchId=${branchId}`;
    }

    return (<>
        {!isShow ? (
            <AppBarNone />
        ) :
            <div className={classes.container}>
                <AppBar />

                <div className={classes.content}>
                    <div className={classes.wrapper}>
                        <div className={classes.headingPath}>
                            <a href={`/privileges?branchId=${branchId}`} className={classes.headingBack}>
                            </a>
                            <div className={classes.headingText}>
                                {data?.titleth || ''}
                            </div>
                        </div>

                        <div className={classes.coupon}>
                            <div className={classes.coupons}>
                                <img
                                    className="coupon__image"
                                    src={data?.assetrectangle}
                                />

                                <div className={classes.remark}>
                                    <div className={classes.remarkHeading}>
                                        เงื่อนไขการใช้คูปอง
                                    </div>
                                    <ul className={classes.remarkGroup}>
                                        {termList.map((m, i) => (
                                            <li className={classes.remarkList}>
                                                <span key={i}>{m}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={classes.action2}>
                            <button
                                className={clsx(classes.actionButton, classes.actionSubmit)}
                                onClick={backToPrillvges}
                                style={{ display: 'inline-block' }}
                            >ไม่ยอมรับ</button>
                            <button
                                className={classes.actionButton}
                                onClick={pickUpCoupon}
                                style={{ display: 'inline-block' }}
                            >ยอมรับ</button>
                        </div>
                    </div>
                </div>

                {loading && <Loading />}

                {picNoReward && (
                    <div className={classes.overlay}>
                        <div className={classes.loader2}>
                            <img
                                className={classes.loaderImage}
                                src={picNoReward}
                                alt="No Reward"
                            />
                            <div>
                                <button
                                    className={clsx(classes.actionButton, classes.actionSubmit)}
                                    onClick={backToPrillvges}
                                    style={{ marginTop: 20 }}
                                >ปิดหน้าต่าง</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        }
    </>)
}

export default PrivilegesDetailRandom;