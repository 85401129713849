import React, { useEffect, useState } from 'react';
import AppBar from '../components/AppBar';
import AppBarNone from '../components/AppBarNone';
import Loading from '../components/Loading';
import '../App.css';
import '../css/awesomplete.css';
import muiStyles from './muiStyle';
import useLiff from '../hooks/useAuth';
import axios from 'axios';
import { API, isDealer } from '../hooks/api';
import clsx from 'clsx';
import { availableMonths, encode, getDateList, getYearList, zeroPadding } from './helpper';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const init = {
    userInfo: null,
    firstname: "",  // ชื่อ
    lastname: "", // นามสกุล
    mobile: "", // เบอร์โทรศัพท์
    province: "",
    district: "",
    tumbon: "",
    postCode: "",
    addressDetail: "", // add
    reservationCode: "",
    model: "", // รุ่นรถ
    size: "", // ขนาดเครื่องยนต์
    manualSelectedModel: "",
    engineNumber: "", // เลขเครื่องยนต์
    bodyNumber: "", // เลขถังรถ
    purchasedDate: "",
    purchasedMonth: "",
    purchasedYear: 2021,

    serviceDate: "", // วันที่ใช้บริการ
    serviceMonth: "",
    serviceYear: 2021,

    couponValue: "", // มูลค่าคูปอง
    purchasedAmount: "",

    usageAmount: "", // มูลค่าที่ซื้อสินค้า
    serviceUsage: "",
    mileageCheckup: "",
    reward: "",

    additionalCost: "",

    couponDetail: "",
    couponTerm: [],
    branchList: [],
    targetBranch: "", // โชว์รูม/ศูนย์บริการ
    targetBranchId: "",

    sparepart: "", // อะไหล่

    isInputDirty: false,
    invalidBranch: false,
    isOverlayActive: false,

    address: "",
}

const MyNewCouponDetailRandom = () => {
    const { id } = useParams()
    const classes = muiStyles();
    const [loading, setLoading] = useState(false);
    const [couponCode, setCouponCode] = useState();
    const [image, setImage] = useState();
    const [engineNumber, setEngineNumber] = useState();

    const [isShow, setShow] = useState(false);

    const [data, setData] = useState({});
    const [termList, setTermList] = useState([]);

    const { isAuthenticated, login, isLogIn, getProfile, getAccessToken } = useLiff();

    const [formData, setFormData] = useState({ ...init });
    const [formBlur, setFormBlur] = useState({});
    const [formType, setFormType] = useState();
    const [rewardType, setRewardType] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [notValidServiceDate, setNotValidServiceDate] = useState(false);

    const [dateList, setDateList] = useState([]); // วัน
    const [monthList, setMonthList] = useState([]); // เดือน
    const [yearList, setYearList] = useState([]); // ปี
    const [showDay, setShowDay] = useState();
    const queryParams = new URLSearchParams(window.location.search);
    const branchId = queryParams.get('branchId');
    const token = getAccessToken
    const profile = getProfile

    useEffect(() => {
        (async () => {
            if (!token) return
            if (!profile?.userId) return
            await getCheckProfile();
        })();
    }, [token, profile]);

    const getCheckProfile = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.CHECK_PROFILE, encodedData);
        if (response?.data?.detail === 'profile_existing') {
            setShow(true);
            (async () => {
                setShow(true);
                await initInfo();
                await initMaster();
            })();
        }
        else {
            window.location = `/`
        }
    }

    const initInfo = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.MY_INFO, encodedData);
        const info = response?.data?.myinfo[0];
        setFormData({
            ...init,
            firstname: info?.firstname,
            lastname: info?.lastname,
            mobile: info?.mobile,
            // engineNumber: info?.enginenumber
        })
        setEngineNumber(info?.enginenumber || '');
    }

    const initMaster = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const coupons = await axios.post(API.LOAD_MY_COUPON, encodedData);
        const items = coupons?.data?.items || [];
        const find = items.find(f => f.itemid === id);
        console.log("coupon", find);
        setData(find);
    }

    useEffect(() => {
        setCouponCode(data?.code);
        setTermList((data?.termth || '').split('!!'));
    }, [data])

    /*** ---------------------- master dropdown ---------------------- ***/

    useEffect(() => {
        const type = data?.type;
        var formType = '';
        if (type === 'RewardRandomNov2021' || type === 'RewardRandom2022-2') {
            formType = 'formTypeReward';
        }
        console.log("Reward type:", type, formType);
        setFormType(formType);
        setRewardType(type);

        const date = new Date();
        const day = date.getDate();
        const month = availableMonths[date.getMonth()].th;
        const year = date.getFullYear() + 543;

        const today = day + " " + month + " " + year;
        setShowDay(today);
    }, [data])

    useEffect(() => {
        if (formType) {
            if (formType === 'formTypeReward') {
                setDateList(getDateList());
                setMonthList([
                    { ...availableMonths[10] }
                ]);
                setYearList([{ th: 2564, en: 2021 }]);
            }
        }
    }, [formType])


    /*** ---------------------- onchange ---------------------- ***/

    const onChangeInput = (e) => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
        setFormBlur({
            ...formBlur,
            [name]: true
        })
    }

    const onBlur = (e) => {
        setFormBlur({
            ...formBlur,
            [e.target.name]: true
        })
    }

    useEffect(() => {
        setIsSubmit(false);
        if (formType === 'formTypeReward') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.address
                // && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
    }, [formData])

    // useEffect(() => {
    //     if (formData.serviceDate && formData.serviceMonth && formData.serviceYear) {
    //         const serviceDate = `${zeroPadding(formData.serviceDate, 2)}/${zeroPadding(formData.serviceMonth, 2)}/${formData.serviceYear}`
    //         if (!moment(serviceDate, "DD/MM/YYYY", true).isValid()) {
    //             setNotValidServiceDate(true);
    //         }
    //         else {
    //             setNotValidServiceDate(false);
    //         }
    //     }
    //     else {
    //         setNotValidServiceDate(false);
    //     }
    // }, [formData])

    /*** ---------------------- submit ---------------------- ***/
    const usedCoupon = () => {
        // if (formData.serviceDate && formData.serviceMonth && formData.serviceYear) {
        //     const serviceDate = `${zeroPadding(formData.serviceDate, 2)}/${zeroPadding(formData.serviceMonth, 2)}/${formData.serviceYear}`
        //     if (!moment(serviceDate, "DD/MM/YYYY", true).isValid()) {
        //         alert('วันที่กดใช้ไม่ถูกต้อง');
        //     }
        //     else {
        //         setShowForm(true);
        //     }
        // }
        // else {
        setShowForm(true);
        // }
    }

    const backToEdit = () => {
        setShowForm(false);
    }

    const onSubmit = async () => {
        // setLoading(true);

        var param = {
            accesstoken: token,
            lineuserid: profile.userId,
            code: couponCode,
            firstname: formData?.firstname,
            lastname: formData?.lastname,
            mobile: formData?.mobile,
            ...(isDealer && { dealerid: branchId }),
        };

        if (branchId && branchId !== 'null') {
            param.dealerid = branchId
        }

        var apiPath = '';

        if (formType === 'formTypeReward') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                address: formData.address,
                // couponusedate:  `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`
            }
        }

        console.log("api", apiPath);
        console.log("param", param);

        const encodedData = encode(param);
        (async () => {
            const response = await axios.post(apiPath, encodedData);
            console.log("response", response);
            if (response?.data?.result === "complete") {
                // alert("complete!!");
                window.location = `/my-used-coupon`
            }
            else {
                setLoading(false);
                if (response?.data?.detail === 'FULLY_ACTIVATE') {
                    alert("ขออภัย ใช้สิทธิ์ครบแล้ว");
                }
                else if (response?.data?.detail === 'Engine_NotFound') {
                    alert("ขออภัย เลขเครื่องยนต์ไม่ถูกต้อง");
                }
                else if (response?.data?.detail === 'Engine_Used') {
                    alert("ขออภัย เลขเครื่องยนต์นี้ถูกใช้งานแล้ว");
                }
                else if (response?.data?.detail === 'CouponCode_Expired') {
                    alert("ขออภัย คูปองหมดอายุแล้ว");
                }
                else if (response?.data?.detail === 'Activate_Limit') {
                    alert("ขออภัย สิทธิ์เต็มแล้ว");
                }
                else {
                    alert(response?.data?.detail || 'ขออภัย ไม่สามารถกดใช้คูปองได้');
                }
            }
        })();
    }

    return (<>
        {!isShow ? (
            <AppBarNone />
        ) :
            <div className={classes.container}>
                <AppBar />

                <div className={classes.content}>
                    <div className={classes.wrapper}>
                        <div className={classes.headingPath}>
                            <a href={`/my-new-coupon`} className={classes.headingBack}>
                            </a>
                            <div className={classes.headingText}>
                                {data?.titleth || ''}
                            </div>
                        </div>

                        {!showForm && (
                            <div className={classes.coupon}>
                                <div className={classes.coupons}>
                                    <div className={classes.couponImageWrap}>
                                        <img
                                            className="coupon__image"
                                            src={image || data?.assetrectangle}
                                        />

                                        <div className={classes.couponNumberWrap}>
                                            <div className={classes.couponNumber}>
                                                {`No. ${data?.code}`}
                                            </div>
                                        </div>
                                    </div>

                                    <form className={classes.form3}>
                                        {true && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    ชื่อ-นามสกุล
                                                </label>
                                                <div>
                                                    <input className={classes.formFieldInput}
                                                        placeholder="ชื่อ-นามสกุล"
                                                        type="text"
                                                        name="firstName"
                                                        value={formData?.firstname + " " + formData?.lastname}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {true && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    เบอร์โทรศัพท์
                                                </label>
                                                <div>
                                                    <input className={classes.formFieldInput}
                                                        placeholder="เบอร์โทรศัพท์"
                                                        type="text"
                                                        name="mobile"
                                                        value={formData?.mobile}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                        maxLength={10}
                                                    />
                                                </div>
                                                {!formData?.mobile && formBlur?.mobile && (
                                                    <div className={classes.formFieldError}>กรุณากรอกข้อมูล</div>
                                                )}
                                                {formData?.mobile && (
                                                    <>
                                                        {isNaN(formData?.mobile) ? (
                                                            <div className={classes.formFieldError}>กรอกเป็นตัวเลขเท่านั้น</div>
                                                        ) : (formData?.mobile || '').length < 10 ? (
                                                            <div className={classes.formFieldError}>กรอกตัวเลข 10 หลัก</div>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                        )}

                                        {~['formTypeReward'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    ที่อยู่
                                                </label>
                                                <div>
                                                    <textarea
                                                        className={classes.formFieldTextArea}
                                                        placeholder="ที่อยู่"
                                                        type="text"
                                                        name="address"
                                                        value={formData?.address}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                        rows={4}
                                                    />
                                                </div>
                                                {!formData?.address && formBlur?.address && (
                                                    <div className={classes.formFieldError}>กรุณากรอกที่อยู่</div>
                                                )}
                                            </div>
                                        )}

                                        {~['formTypeReward'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    วันที่กดใช้
                                                </label>
                                                <div className={classes.redeemDetail2}>
                                                    {showDay}
                                                </div>
                                                {/* <div style={{ width: '100%', display: 'flex' }}>
                                            <div className={classes.formBirthDayDate}>
                                                <select
                                                    className={formData.serviceDate ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                    name="serviceDate"
                                                    value={formData.serviceDate}
                                                    onChange={onChangeInput}
                                                    onBlur={onBlur}
                                                >
                                                    <option value="">วันที่</option>
                                                    {dateList.map(m => (
                                                        <option value={m} key={m}>{m}</option>
                                                    ))}
                                                </select>
                                                {!formData.serviceDate && formBlur.serviceDate && (
                                                    <div className={classes.formFieldError}>
                                                        กรุณาระบุวันที่
                                                    </div>
                                                )}
                                            </div>
                                            <div className={classes.formBirthDayMonth}>
                                                <select
                                                    className={formData.serviceMonth ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                    name="serviceMonth"
                                                    value={formData.serviceMonth}
                                                    onChange={onChangeInput}
                                                    onBlur={onBlur}
                                                >
                                                    <option value="">เดือน</option>
                                                    {monthList.map((m, i) => (
                                                        <option value={m.key} key={m.key}>{m.th}</option>
                                                    ))}
                                                </select>
                                                {!formData.serviceMonth && formBlur.serviceMonth && (
                                                    <div className={classes.formFieldError}>
                                                        กรุณาระบุเดือน
                                                    </div>
                                                )}
                                            </div>
                                            <div className={classes.formBirthDayYear}>
                                                <select
                                                    className={formData.serviceYear ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                    name="serviceYear"
                                                    value={formData.serviceYear}
                                                    onChange={onChangeInput}
                                                    onBlur={onBlur}
                                                >
                                                    <option value="">ปี</option>
                                                    {yearList.map(m => (
                                                        <option value={m.en} key={m.en}>{m.th}</option>
                                                    ))}
                                                </select>
                                                {!formData.serviceYear && formBlur.serviceYear && (
                                                    <div className={classes.formFieldError}>
                                                        กรุณาระบุปี
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {notValidServiceDate && (
                                            <div className={classes.formFieldError}>
                                                วันที่ใช้บริการไม่ถูกต้อง
                                            </div>
                                        )} */}
                                            </div>
                                        )}
                                    </form>

                                    <div className={classes.remark}>
                                        <div className={classes.remarkHeading}>
                                            เงื่อนไขการใช้คูปอง
                                        </div>
                                        <ul className={classes.remarkGroup}>
                                            {termList.map((m, i) => (
                                                <li className={classes.remarkList}>
                                                    <span key={i}>{m}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showForm && (
                            <div className={classes.coupon}>
                                <div className={classes.coupons}>
                                    <div className={classes.couponImageWrap}>
                                        <img
                                            className="coupon__image"
                                            src={image || data?.assetrectangle}
                                        />

                                        <div className={classes.couponNumberWrap}>
                                            <div className={classes.couponNumber}>
                                                {`No. ${data?.code}`}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.redeem}>
                                        <div className={classes.redeemBox}>
                                            <div className={classes.redeemHeader}>
                                                ชื่อ-นามสกุล
                                            </div>
                                            <div className={classes.redeemDetail}>
                                                {formData?.firstname} {formData?.lastname}
                                            </div>
                                        </div>

                                        <div className={classes.redeemBox}>
                                            <div className={classes.redeemHeader}>
                                                เบอร์โทรศัพท์
                                            </div>
                                            <div className={classes.redeemDetail}>
                                                {formData?.mobile}
                                            </div>
                                        </div>

                                        {~['formTypeReward'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <div className={classes.redeemHeader}>
                                                    ที่อยู่
                                                </div>
                                                <div className={classes.redeemDetail}>
                                                    {formData?.address}
                                                </div>
                                            </div>
                                        )}

                                        {~['formTypeReward'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <div className={classes.redeemHeader}>
                                                    วันที่กดใช้
                                                </div>
                                                <div className={classes.redeemDetail}>
                                                    {showDay}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className={classes.confirmRemark}>
                                        <p className={classes.confirmParagraph}>
                                            {`หากข้อมูลถูกต้อง กรุณากด `}
                                            <span className={classes.confirmHilight}>'ยืนยันข้อมูล'</span>
                                            {` อีกครั้ง เพื่อบันทึกข้อมูลการใช้
                                    และเข้าไปที่หน้า คูปองที่ใช้แล้ว/หมดอายุ
                                    เพื่อแคปหน้าจอเป็นหลักฐาน`}
                                        </p>
                                        <p className={classes.confirmParagraph}>
                                            {`หากไม่กด `}
                                            <span className={classes.confirmUnderLine}>'ยืนยันข้อมูล'</span>
                                            {` จะถือว่าการใช้ไม่สมบูรณ์
                                    และสิทธิ์จะเป็นโมฆะทันที`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showForm ? (
                            <div className={classes.action2}>
                                <button
                                    className={classes.actionButton}
                                    onClick={backToEdit}
                                    style={{ display: 'inline-block' }}
                                >แก้ไขข้อมูล</button>
                                <button
                                    className={clsx(classes.actionButton, classes.actionSubmit)}
                                    onClick={onSubmit}
                                    style={{ display: 'inline-block' }}
                                >ยืนยันข้อมูล</button>
                            </div>
                        ) :
                            <div className={classes.action}>
                                <button
                                    className={isSubmit ? classes.actionRedeem : clsx(classes.actionRedeem, classes.actionRedeemDisabled)}
                                    onClick={usedCoupon}
                                    disabled={!isSubmit}
                                >กดใช้คูปอง</button>
                            </div>
                        }
                    </div>
                </div>

                {loading && <Loading />}
            </div>
        }
    </>)
}

export default MyNewCouponDetailRandom;