import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Privileges from './page/Privilleges';
import MyUsedCoupon from './page/MyUsedCoupon';
import MyProfile from './page/MyProfile';
import MyNewCoupon from './page/MyNewCoupon';
import MyNewCouponDetail from './page/MyNewCouponDetail';
import MyNewCouponDetailRandom from './page/MyNewCouponDetailRandom';
import Index from './page';
import PrivilegesDetail from './page/PrivillegesDetail';
import PrivilegesDetailRandom from './page/PrivillegesDetailRandom';
import { LiffProvider } from './contexts/LiffContext'
import MyUsedCouponDetail from './page/MyUsedCouponDetail';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

export function App() {
  const queryClient = new QueryClient()
  return (
    <Router>
      <LiffProvider>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route path="/" component={Index} exact={true} />
            <Route path="/privileges" component={Privileges} exact={true} />
            <Route path="/my-used-coupon" component={MyUsedCoupon} exact={true} />
            <Route path="/my-new-coupon" component={MyNewCoupon} exact={true} />
            <Route path="/my-profile" component={MyProfile} exact={true} />
            <Route path="/privileges-detail/:id" component={PrivilegesDetail} exact={true} />
            <Route path="/privileges-detail-random/:id" component={PrivilegesDetailRandom} exact={true} />
            <Route path="/coupon-detail/:id" component={MyNewCouponDetail} exact={true} />
            <Route path="/coupon-detail-random/:id" component={MyNewCouponDetailRandom} exact={true} />
            <Route path="/my-used-coupon-detail/:id" component={MyUsedCouponDetail} exact={true} />
          </Switch>
        </QueryClientProvider>
      </LiffProvider>
    </Router>
  );
}

export default App;