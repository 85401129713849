export const availableMonths = [
    {
        key: 1,
        th: "มกราคม",
        en: "January",
    },
    {
        key: 2,
        th: "กุมภาพันธ์",
        en: "February",
    },
    {
        key: 3,
        th: "มีนาคม",
        en: "March",
    },
    {
        key: 4,
        th: "เมษายน",
        en: "April	",
    },
    {
        key: 5,
        th: "พฤษภาคม",
        en: "May",
    },
    {
        key: 6,
        th: "มิถุนายน",
        en: "June",
    },
    {
        key: 7,
        th: "กรกฎาคม",
        en: "July",
    },
    {
        key: 8,
        th: "สิงหาคม",
        en: "August",
    },
    {
        key: 9,
        th: "กันยายน",
        en: "September",
    },
    {
        key: 10,
        th: "ตุลาคม",
        en: "October",
    },
    {
        key: 11,
        th: "พฤศจิกายน",
        en: "November",
    },
    {
        key: 12,
        th: "ธันวาคม",
        en: "December",
    },
]

export function getDateList(min = 1, max = 31){
    var list = [];
    for(var i = min; i <= max; i++) {
        list.push(i)
    }
    return list;
}

export function getYearList(){
    var list = [];
    var year = new Date().getFullYear();
    for(var i = year; i > year - 100; i--) {
        list.push({
            th: i + 543,
            en: i
        })
    }
    return list;
}

export function zeroPadding(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length - size);
}

export function humanExpireTime(string){
    if (!string) return '';
    const dateString = string.split(" ")[0];
    const year = parseInt(dateString.split("-")[0], 10) + 543;
    const month = availableMonths[parseInt(dateString.split("-")[1] - 1, 10)].th;
    const date = parseInt(dateString.split("-")[2], 10);

    return `${date} ${month} ${year}`;
}

export function encode(param){
    const jsonText = JSON.stringify(param);
    return btoa(
        encodeURIComponent(jsonText).replace(
            /%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode("0x" + p1);
            }
        )
    );
}