import axios from "axios"
import { API } from './api'


export const checkProfile = async (encodedData) => {
  const { data } = await axios.post(API.CHECK_PROFILE, encodedData);
  return data
}

export const getModel = async (encodedData) => {
  const { data } = await axios.post(API.GET_MODEL, encodedData);
  return data
}