import React, {
  createContext, useEffect, useState,
} from 'react';
import liff from '@line/liff'
import { liffId } from '../hooks/api';

const LiffContext = createContext()

export const LiffProvider = ({ children }) => {
  const [auth, setAuth] = useState({})
  const fetchLiff = async () => {
    await liff.init({ liffId: liffId }).catch(err=>{throw err});
      if (liff.isLoggedIn()) {
        let getProfile = await liff.getProfile();
        setAuth({
          getProfile: getProfile,
          isLogIn: liff.isLoggedIn,
          getAccessToken: liff.getAccessToken()
        })
      } else {
        liff.login();
      }
  }

  useEffect(() => {
    fetchLiff()
  }, [])


  return (
    <LiffContext.Provider value={auth}>
      {children}
    </LiffContext.Provider>
  );
};

export default LiffContext